import { omit, first, findIndex, upperCase, get, pick, isEmpty } from 'lodash';

import * as api from '@/modules/dashboard/api';

import {
  getComplementaryCareTreatment,
  getTreatSectionBottles,
  isBottleEmpty,
  isOneOfBottlesDuplicated,
  getMicroneedlingConfig
} from '@/modules/dashboard/utils/create-treatment-utils';

import { compressImageFile } from '@/modules/questionnaire/utils';

import { dashTypes } from '@/modules/dashboard/store/types';

import {
  CREATE_TREATMENT_TYPES,
  DEFAULT_CREATE_TREATMENT_SUMMARY,
  CREATE_TREATMENT_COMPLEMENTARY_CARE_SECTIONS,
  CREATE_TREATMENT_ERRORS,
  PHOTO_ANALYSIS_INITIAL_STATE,
  CREATE_TREATMENT_SECTION_NAMES,
  PRODUCT_REFERENCES,
  ANALYSIS_RESULT_COLORS_ARRAY,
  TREATMENT_TYPES,
  TEMPLATE_GROUP_TYPES,
  MICRONEEDLING_PRODUCT_SECTION_TO_PRODUCT_REFERENCE_MAP,
  SERUM_BOTTLE_KEY,
  TREATMENT_SECTION_NAME,
  RESTRICTED_MOISTURIZE_PRODUCTS,
  SERUM_USAGE_KEYS,
  SPF_WARNING_RECOMMENDATION
} from '@/modules/dashboard/api/constants';
import { getDefaultProductsBySectionName } from '@/modules/dashboard/utils';
import {
  checkMoisturizeSelection,
  removeFromMoisturizeUsages,
  handleMorningOrEveningUsageSelection
} from '@/modules/dashboard/business-logic/moisturize-products-logic';
import { EMAIL_ERRORS } from '@/constants';

const PATIENT_INFO_EMAIL_ERROR_REGEXP = {
  TYPE: /([A-Z_]{1,}),/,
  DATA: /{(.*)}/
};

const checkEmailErrors = (commit, response) => {
  const { description: error, warning } = response;

  if (warning) {
    commit(dashTypes.mutations.ADD_ERROR, {
      type: warning,
      data: response
    });

    return;
  }

  const isPatientEmailError = PATIENT_INFO_EMAIL_ERROR_REGEXP.TYPE.test(error);

  if (!isPatientEmailError) {
    commit(dashTypes.mutations.ADD_ERROR, { type: error });

    return;
  }

  const [errorTypeMatch] = error.match(PATIENT_INFO_EMAIL_ERROR_REGEXP.TYPE);
  const type = errorTypeMatch.slice(0, -1);

  const [errorDataMatch] = error.match(PATIENT_INFO_EMAIL_ERROR_REGEXP.DATA);
  const data = errorDataMatch.slice(1, -1);

  commit(dashTypes.mutations.ADD_ERROR, {
    type,
    data
  });
};

const TREATMENT_SECTION_NAME_TO_MUTATION_TYPE_MAP = {
  [TREATMENT_SECTION_NAME.TREAT_FACE]: dashTypes.mutations.SET_TREAT_FACE,
  [TREATMENT_SECTION_NAME.TREAT_EYES]: dashTypes.mutations.SET_TREAT_EYES
};

export default {
  async [dashTypes.actions.UPDATE_NOTIFICATIONS_COUNTER]({ commit, rootGetters: { getUserId } }) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: notificationsCounter } = await api.countNotifications({
        doctorId: getUserId
      });

      commit(dashTypes.mutations.SET_NOTIFICATIONS_COUNTER, notificationsCounter || 0);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, 'Error during notification counter loading');
    }
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [dashTypes.actions.LOAD_PATIENT]({ commit }, patientId) {
    commit(dashTypes.mutations.CLEAR_ERRORS);
    commit(dashTypes.mutations.SET_LOADING, true);

    try {
      const { data: patient } = await api.fetchPatient({
        patientId
      });

      if (!patient.id) {
        commit(dashTypes.mutations.ADD_ERROR, 'Patient not found!');

        return;
      }

      commit(dashTypes.mutations.SET_CURRENT_PATIENT, patient);

      const { data: diagnostics } = await api.getPatientDiagnostics(patientId);

      if (!diagnostics) {
        commit(dashTypes.mutations.ADD_ERROR, 'Error while fetching patient diagnostics!');

        return;
      }

      commit(dashTypes.mutations.SET_CURRENT_PATIENT_DIAGNOSTICS, diagnostics);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.response);
    }
    commit(dashTypes.mutations.SET_LOADING, false);
  },

  async [dashTypes.actions.CREATE_PATIENT]({ commit, rootGetters }, patientData) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const doctorId = rootGetters.getUserId;
      const { data: response } = await api.createPatient({
        doctorId,
        ...patientData
      });

      const { id, warning } = response;

      if (!id || (warning && warning !== EMAIL_ERRORS.EMAIL_USED_BY_ANOTHER_DOCTOR_PATIENT)) {
        checkEmailErrors(commit, response);

        return;
      }

      commit(dashTypes.mutations.SET_CURRENT_PATIENT, response);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  // -------------------------------------------------------------------------------------------------------------------

  [dashTypes.actions.UPDATE_FILTER]({ state, commit, dispatch }, value) {
    const { statuses, clinics, sources, influncerId, count } = state.patientFilter;

    if (count !== null) {
      dispatch(dashTypes.actions.UPDATE_PATIENT_FILTER_COUNT, {
        statuses,
        clinics,
        sources,
        influncerId,
        search: value
      });
    }

    commit(dashTypes.mutations.SET_UPDATE_FILTER, value);
  },

  // -------------------------------------------------------------------------------------------------------------------

  [dashTypes.actions.SET_CURRENT_PATIENT]({ commit }, patient) {
    commit(dashTypes.mutations.SET_CURRENT_PATIENT, patient);
  },

  async [dashTypes.actions.LOAD_CURRENT_PATIENT_TREATMENTS]({
    commit,
    getters: { currentPatientId }
  }) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: treatments } = await api.getPatientTreatments(currentPatientId);

      if (treatments) {
        commit(dashTypes.mutations.SET_CURRENT_PATIENT_TREATMENTS, treatments);
      }
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, 'Cannot load current patient treatments');
    }
  },

  async [dashTypes.actions.UPLOAD_CURRENT_PATIENT_PHOTO](
    { commit, getters },
    { file, isPhotoAnalysis, storePhoto, allowResearch }
  ) {
    const compressedPhoto = await compressImageFile(file);
    const {
      data: { photoId }
    } = await api.addPatientPhoto({
      patientId: getters.currentPatient.id,
      photo: compressedPhoto,
      storePhoto,
      allowResearch
    });

    if (!isPhotoAnalysis) {
      return;
    }

    commit(dashTypes.mutations.SET_PHOTO_ANALYSIS, {
      photoId,
      hasPermissionToDelete: true,
      storeImageOption: storePhoto
    });
  },

  async [dashTypes.actions.RUN_PHOTO_ANALYSIS_ALGORITHM]({ commit, getters }, locale) {
    const { data } = await api.getPhotoAnalysisRecommendation(
      getters.currentPatient.id,
      getters.photoAnalysis.photoId
    );

    if (!data) {
      return { errors: ['Something went wrong'] };
    }

    const diagnosticScores = pick(data.diagnostic, ANALYSIS_RESULT_COLORS_ARRAY);

    const { data: spiderChart } = await api.getSpiderChartImage(diagnosticScores, locale);

    if (!spiderChart) {
      return { errors: ['Spider chart is not loaded'] };
    }

    commit(dashTypes.mutations.SET_PHOTO_ANALYSIS_RESULTS, data);
    commit(dashTypes.mutations.SET_PHOTO_ANALYSIS_SPIDER_CHART, spiderChart);
    commit(dashTypes.mutations.SET_PHOTO_ANALYSIS_UPDATED_RESULTS, data.diagnosticv2);

    return {};
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [dashTypes.actions.LOAD_PRICE_SETTING]({ commit, rootState }) {
    try {
      const doctorId = rootState.user.id;
      const { data: result } = await api.getSettings(doctorId);

      commit(dashTypes.mutations.SET_LOAD_SETTING, result);
    } catch (error) {
      commit(dashTypes.mutations.SET_ERROR, error.response);
    }
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [dashTypes.actions.UPDATE_PATIENT]({ commit, state }, payload) {
    try {
      commit(dashTypes.mutations.SET_UPDATE_PATIENT, payload);

      const { currentPatient } = state;

      const currentPatientData = omit(currentPatient, ['medicalDataDto', 'preSelectedFormula']);

      await api.updatePatient(currentPatient.id, currentPatientData);
    } catch (error) {
      commit(dashTypes.mutations.SET_ERROR, error.response);
    }
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [dashTypes.actions.UPDATE_PRICE]({ commit, rootGetters: { getUserId } }, productPrices) {
    try {
      await api.updatePrice(getUserId, productPrices);

      commit(dashTypes.mutations.SET_UPDATE_PRICE, productPrices);
    } catch (error) {
      commit(dashTypes.mutations.SET_ERROR, error.response);
    }
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [dashTypes.actions.VALIDATE_DIAGNOSTIC]({ state, commit }, payload) {
    const { diagnostic } = payload;

    try {
      const patientId = state.currentPatient.id;

      const content = {
        diagnostic,
        validated: true
      };

      commit(dashTypes.mutations.SET_NOT_VALIDATED_DIAGNOSTIC_ID, null);
      commit(dashTypes.mutations.SET_DIAGNOSTIC_VALIDATED, content);

      await api.updateDiagnostic(patientId, diagnostic.questionnaireId, diagnostic);
    } catch (error) {
      const content = {
        diagnostic,
        validated: false
      };

      commit(dashTypes.mutations.SET_DIAGNOSTIC_VALIDATED, content);
      commit(dashTypes.mutations.SET_ERROR, error.response);
    }
  },

  // -------------------------------------------------------------------------------------------------------------------

  async [dashTypes.actions.DELETE_PATIENT]({ commit }, id) {
    try {
      await api.deletePatient(id);
    } catch (error) {
      commit(dashTypes.mutations.SET_ERROR, error.response);
    }
  },

  // -------------------------------------------------------------------------------------------------------------------

  [dashTypes.actions.SET_PATIENT_ORDER]({ commit }, order) {
    commit(dashTypes.mutations.SET_PATIENT_ORDER, order);
  },

  [dashTypes.actions.SET_PHOTO_ANALYSIS]({ commit }, photoAnalysis) {
    commit(dashTypes.mutations.SET_PHOTO_ANALYSIS, photoAnalysis);
  },

  [dashTypes.actions.SET_PHOTO_ANALYSIS_UPDATED_RESULTS]({ commit }, results) {
    commit(dashTypes.mutations.SET_PHOTO_ANALYSIS_UPDATED_RESULTS, results);
  },

  [dashTypes.actions.RESET_PHOTO_ANALYSIS]({ commit }) {
    commit(dashTypes.mutations.SET_PHOTO_ANALYSIS, { ...PHOTO_ANALYSIS_INITIAL_STATE });
  },

  [dashTypes.actions.SET_PATIENT_DATE_ORDER]({ commit }, dateOrder) {
    commit(dashTypes.mutations.SET_PATIENT_DATE_ORDER, dateOrder);
  },

  [dashTypes.actions.SET_REGISTRATION_DATE_SORTING_DIRECTION]({ commit }, direction) {
    commit(dashTypes.mutations.SET_REGISTRATION_DATE_SORTING_DIRECTION, direction);
  },

  [dashTypes.actions.SET_OPENED_PATIENT]({ commit }, openedPatient) {
    commit(dashTypes.mutations.SET_OPENED_PATIENT, openedPatient);
  },

  [dashTypes.actions.RESET_OPENED_PATIENT]({ commit }) {
    commit(dashTypes.mutations.SET_OPENED_PATIENT, null);
  },

  [dashTypes.actions.SET_LAST_ACTIVITY_SORTING_DIRECTION]({ commit }, direction) {
    commit(dashTypes.mutations.SET_LAST_ACTIVITY_SORTING_DIRECTION, direction);
  },

  [dashTypes.actions.SET_PATIENT_NAME_SORTING_DIRECTION]({ commit }, direction) {
    commit(dashTypes.mutations.SET_PATIENT_NAME_SORTING_DIRECTION, direction);
  },

  [dashTypes.actions.SET_ACTIVE_DIAGNOSTIC_ID]({ commit }, diagnosticId) {
    commit(dashTypes.mutations.SET_ACTIVE_DIAGNOSTIC_ID, diagnosticId);
  },

  [dashTypes.actions.SET_ACTIVE_TREATMENT_ID]({ commit }, treatmentId) {
    commit(dashTypes.mutations.SET_ACTIVE_TREATMENT_ID, treatmentId);
  },

  [dashTypes.actions.SET_SUMMARY_TREATMENT_ID]({ commit }, treatmentId) {
    commit(dashTypes.mutations.SET_SUMMARY_TREATMENT_ID, treatmentId);
  },

  [dashTypes.actions.SET_FIRST_DIAGNOSTIC_AS_ACTIVE]({ commit, getters }) {
    const { id: firstDiagnosticId } = first(getters.currentPatientDiagnostics) || {};

    commit(dashTypes.mutations.SET_ACTIVE_DIAGNOSTIC_ID, firstDiagnosticId || null);
  },

  [dashTypes.actions.RESET_ACTIVE_DIAGNOSTIC_ID]({ commit }) {
    commit(dashTypes.mutations.SET_ACTIVE_DIAGNOSTIC_ID, null);
  },

  [dashTypes.actions.SET_FIRST_TREATMENT_AS_ACTIVE]({ commit, getters }) {
    const { id: firstTreatmentId } = first(getters.currentPatientTreatments) || {};

    commit(dashTypes.mutations.SET_ACTIVE_TREATMENT_ID, firstTreatmentId || null);
  },

  [dashTypes.actions.RESET_ACTIVE_TREATMENT]({ commit }) {
    commit(dashTypes.mutations.SET_ACTIVE_TREATMENT_ID, null);
  },

  [dashTypes.actions.RESET_SUMMARY_TREATMENT_ID]({ commit }) {
    commit(dashTypes.mutations.RESET_SUMMARY_TREATMENT_ID, null);
  },

  [dashTypes.actions.SET_ACTIVE_PATIENT_TAB]({ commit }, activeTabName) {
    commit(dashTypes.mutations.SET_ACTIVE_PATIENT_TAB, activeTabName);
  },

  [dashTypes.actions.SET_ACTIVE_INVITE_TAB]({ commit }, activeTabName) {
    commit(dashTypes.mutations.SET_ACTIVE_INVITE_TAB, activeTabName);
  },

  [dashTypes.actions.SET_PATIENT_FILTER]({ commit }, patientFilter) {
    commit(dashTypes.mutations.SET_PATIENT_FILTER, patientFilter);
  },

  [dashTypes.actions.RESET_PATIENT_FILTER]({ commit }) {
    commit(dashTypes.mutations.RESET_PATIENT_FILTER);
  },

  async [dashTypes.actions.UPDATE_PATIENT_FILTER_COUNT](
    { state, commit, rootGetters },
    {
      statuses: statusNames,
      clinics: clinicIds,
      sources: appearanceSources,
      influencers: influencerIds,
      search: searchString
    }
  ) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { partialName } = state;
      const doctorId = rootGetters.getUserId;

      const search =
        searchString !== undefined && searchString !== null ? searchString : partialName;

      const { data: count } = await api.countPatients({
        doctorId,
        search,
        statusNames,
        clinicIds,
        influencerIds,
        appearanceSources
      });

      commit(dashTypes.mutations.SET_PATIENT_FILTER_COUNT, Number.isInteger(count) ? count : null);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.response);
    }
  },

  [dashTypes.actions.SET_PDF_SUMMARY]({ commit }, pdfSummary) {
    commit(dashTypes.mutations.SET_PDF_SUMMARY, pdfSummary);
  },

  [dashTypes.actions.RESET_PDF_SUMMARY]({ commit }) {
    commit(dashTypes.mutations.RESET_PDF_SUMMARY);
  },

  async [dashTypes.actions.SELECT_CREATE_TREATMENT_TYPE]({ commit, dispatch, getters }, options) {
    const { currentPatientId } = getters;

    const {
      createTreatmentType,
      recommendationDiagnostic,
      treatmentDirectoryRecommendations
    } = options;

    dispatch(dashTypes.actions.RESET_CREATE_TREATMENT_SELECTIONS);

    if (CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT === createTreatmentType) {
      const { notValidated = false } = await dispatch(
        dashTypes.actions.SELECT_RECOMMENDATION_DIAGNOSTIC,
        recommendationDiagnostic
      );

      if (notValidated) {
        await dispatch(dashTypes.actions.SELECT_CREATE_TREATMENT_TYPE, {
          createTreatmentType: CREATE_TREATMENT_TYPES.TREATMENT_FROM_SCRATCH
        });

        return { diagnosticNotValidated: notValidated };
      }

      await dispatch(dashTypes.actions.LOAD_TREATMENT_RECOMMENDATION, {
        patientId: currentPatientId,
        diagnosticId: get(recommendationDiagnostic, 'id', null)
      });
    }

    if (CREATE_TREATMENT_TYPES.TREATMENT_DIRECTORY === createTreatmentType) {
      commit(
        dashTypes.mutations.SET_TREATMENT_DIRECTORY_RECOMMENDATIONS,
        treatmentDirectoryRecommendations
      );

      dispatch(dashTypes.actions.SELECT_TREATMENT_DIRECTORY_RECOMMENDATIONS);
    }

    commit(dashTypes.mutations.SET_CREATE_TREATMENT_TYPE, createTreatmentType);
    await dispatch(dashTypes.actions.SET_COMPLEMENTARY_CARE_SECTIONS_PRODUCTS);
  },

  [dashTypes.actions.RESET_CREATE_TREATMENT_TYPE]({ commit }) {
    commit(
      dashTypes.mutations.SET_CREATE_TREATMENT_TYPE,
      CREATE_TREATMENT_TYPES.DIAGNOSTIC_BASED_TREATMENT
    );
  },

  async [dashTypes.actions.SELECT_RECOMMENDATION_DIAGNOSTIC](
    { commit, getters },
    recommendationDiagnostic
  ) {
    if (!recommendationDiagnostic || !recommendationDiagnostic.id) {
      throw new Error('No recommendation diagnostic provided');
    }

    const { currentPatientDiagnostics } = getters;

    if (isEmpty(currentPatientDiagnostics)) {
      throw new Error('Patient has no diagnostics');
    }

    const { id: diagnosticId, validated } = recommendationDiagnostic;

    const hasDiagnosticInCurrentPatientDiagnostics = currentPatientDiagnostics
      .map(({ id }) => id)
      .includes(diagnosticId);

    if (!hasDiagnosticInCurrentPatientDiagnostics) {
      throw new Error('Selected recommendation diagnostic not from current patient diagnostics');
    }

    if (!validated) {
      commit(dashTypes.mutations.SET_NOT_VALIDATED_DIAGNOSTIC_ID, diagnosticId);

      return { notValidated: true };
    }

    commit(dashTypes.mutations.SET_RECOMMENDATION_DIAGNOSTIC, recommendationDiagnostic);

    return {};
  },

  [dashTypes.actions.RESET_RECOMMENDATION_DIAGNOSTIC]({ commit }) {
    commit(dashTypes.mutations.SET_RECOMMENDATION_DIAGNOSTIC, null);
  },

  async [dashTypes.actions.LOAD_TREATMENT_RECOMMENDATION]({ commit, getters, dispatch }, options) {
    const { patientId, diagnosticId } = options;
    const { currentPatientId, getCurrentPatientDiagnosticById } = getters;
    const patientDiagnostic = getCurrentPatientDiagnosticById(diagnosticId);

    if (currentPatientId === patientId && !patientDiagnostic) {
      dispatch(dashTypes.actions.SELECT_CREATE_TREATMENT_TYPE, {
        createTreatmentType: CREATE_TREATMENT_TYPES.TREATMENT_FROM_SCRATCH
      });

      throw new Error('Tried to load recommendations for another patient diagnostic');
    }

    const { data: treatmentRecommendation } = await api.getTreatmentRecommendationsByDiagnostic(
      patientId,
      diagnosticId
    );
    commit(dashTypes.mutations.SET_TREATMENT_RECOMMENDATION, treatmentRecommendation);
  },

  [dashTypes.actions.SET_TREATMENT_RECOMMENDATION]({ commit }, treatmentRecommendation) {
    commit(dashTypes.mutations.SET_TREATMENT_RECOMMENDATION, treatmentRecommendation);
  },

  [dashTypes.actions.RESET_TREATMENT_RECOMMENDATION]({ commit }) {
    commit(dashTypes.mutations.SET_TREATMENT_RECOMMENDATION, null);
    commit(dashTypes.mutations.RESET_TREATMENT_DIRECTORY);
  },

  [dashTypes.actions.UPDATE_COMPLEMENTARY_CARE_PRODUCT]({ commit, getters }, { section, product }) {
    const upperCaseSectionName = upperCase(section);

    const sectionProducts = getters.sectionProducts[section];

    const updatedSectionProducts = sectionProducts.map(sectionProduct => {
      if (sectionProduct.name === product.name) {
        return product;
      }

      return sectionProduct;
    });

    commit(dashTypes.mutations[`SET_${upperCaseSectionName}_PRODUCTS`], updatedSectionProducts);
  },

  [dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT](
    { commit, getters },
    { section, product, isActive }
  ) {
    const upperCaseSectionName = upperCase(section);
    const sectionSummary = getters.summaryTreatment[section];

    if (sectionSummary.includes(product.name) && isActive) {
      return;
    }

    let updatedSectionSummary;

    if (isActive) {
      updatedSectionSummary = [...sectionSummary, product.name];
    } else {
      updatedSectionSummary = sectionSummary.filter(name => name !== product.name);
    }

    commit(dashTypes.mutations[`SET_${upperCaseSectionName}_TREATMENT`], updatedSectionSummary);
  },

  [dashTypes.actions.UPDATE_SUMMARY_TREATMENT]({ commit, getters }, updates) {
    commit(dashTypes.mutations.SET_SUMMARY_TREATMENT, { ...getters.summaryTreatment, ...updates });
  },

  [dashTypes.actions.SET_SUMMARY_TREATMENT_BASED_ON_CREATED_TREATMENT](
    { commit, dispatch, getters: { availableComplementaryCare: availableProducts } },
    treatment
  ) {
    if (!treatment || !treatment.treatmentDetails) {
      return;
    }

    const {
      treatmentDetails: { treatFace, treatEyes, cleanse, moisturize, protect },
      treatmentDirectoryId,
      type
    } = treatment;

    const summaryTreatment = {
      treatFace: getTreatSectionBottles(treatFace),
      treatEyes: getTreatSectionBottles(treatEyes),
      cleanse: getComplementaryCareTreatment(cleanse, availableProducts),
      moisturize: getComplementaryCareTreatment(moisturize, availableProducts),
      protect: getComplementaryCareTreatment(protect, availableProducts)
    };

    dispatch(dashTypes.actions.SET_TREATMENT_RECOMMENDATION, {
      cleanse,
      moisturize,
      protect
    });

    dispatch(dashTypes.actions.SET_COMPLEMENTARY_CARE_SECTIONS_PRODUCTS);
    dispatch(dashTypes.actions.RESET_TREATMENT_RECOMMENDATION);
    dispatch(dashTypes.actions.UPDATE_SUMMARY_TREATMENT, summaryTreatment);

    commit(dashTypes.mutations.SET_RECOMMENDATION_DIAGNOSTIC, { id: treatment.diagnosticId });

    if (type === TREATMENT_TYPES.TREATMENT_DIRECTORY) {
      commit(dashTypes.mutations.SET_TREATMENT_DIRECTORY_RECOMMENDATIONS, {
        treatmentRecommendationId: treatmentDirectoryId,
        treatmentComposition: {
          treatFace,
          treatEyes,
          cleanse,
          moisturize,
          protect
        }
      });
    }
  },

  [dashTypes.actions.RESET_SUMMARY_TREATMENT]({ commit }) {
    commit(dashTypes.mutations.SET_SUMMARY_TREATMENT, { ...DEFAULT_CREATE_TREATMENT_SUMMARY });
  },

  [dashTypes.actions.SET_COMPLEMENTARY_CARE_SECTIONS_PRODUCTS]({
    commit,
    dispatch,
    getters: {
      treatmentRecommendation,
      availableComplementaryCare,
      treatmentDirectoryRecommendations
    }
  }) {
    const hasRecommendation = !!treatmentRecommendation;
    const hasTreatmentDirectoryRecommendations = !!treatmentDirectoryRecommendations;

    CREATE_TREATMENT_COMPLEMENTARY_CARE_SECTIONS.forEach(sectionName => {
      let products;

      if (hasTreatmentDirectoryRecommendations) {
        const directoryProducts = get(
          treatmentDirectoryRecommendations,
          `treatmentComposition.${sectionName}.products`,
          []
        );

        products = getDefaultProductsBySectionName(sectionName, availableComplementaryCare).map(
          defaultProduct => {
            const recommendedProduct = directoryProducts.find(
              ({ name }) => name === defaultProduct.name
            );

            const isRecommended = !!recommendedProduct;
            const recommendedProductUsage = get(recommendedProduct, 'usage');
            const usage = recommendedProductUsage || defaultProduct.usage;

            return {
              ...defaultProduct,
              usage,
              isRecommended
            };
          }
        );
      } else if (hasRecommendation) {
        const recommendedProducts = get(treatmentRecommendation, `${sectionName}.products`, []);

        products = recommendedProducts.filter(({ name }) =>
          availableComplementaryCare.includes(name)
        );
      } else {
        products = getDefaultProductsBySectionName(sectionName, availableComplementaryCare);
      }

      const SECTION_NAME_TO_MUTATION_MAP = {
        [CREATE_TREATMENT_SECTION_NAMES.CLEANSE]: dashTypes.mutations.SET_CLEANSE_PRODUCTS,
        [CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE]: dashTypes.mutations.SET_MOISTURIZE_PRODUCTS,
        [CREATE_TREATMENT_SECTION_NAMES.PROTECT]: dashTypes.mutations.SET_PROTECT_PRODUCTS
      };

      commit(SECTION_NAME_TO_MUTATION_MAP[sectionName], products);
    });

    if (!hasRecommendation) {
      return;
    }

    const moisturizeProducts = get(
      treatmentRecommendation,
      `${CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE}.products`,
      []
    );

    const { isRecommended: isUniverskinHRecommended } =
      moisturizeProducts.find(({ name }) => name === PRODUCT_REFERENCES.UNIVERSKIN_H) || {};
    const shouldSetupUniverskinHSection =
      isUniverskinHRecommended &&
      availableComplementaryCare.includes(PRODUCT_REFERENCES.UNIVERSKIN_H);

    if (shouldSetupUniverskinHSection) {
      dispatch(
        dashTypes.actions.SET_UNIVERSKIN_H_SECTION_SELECTION,
        CREATE_TREATMENT_SECTION_NAMES.TREAT
      );

      dispatch(dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT, {
        section: CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE,
        product: { name: PRODUCT_REFERENCES.UNIVERSKIN_H },
        isActive: true
      });
    }
  },

  [dashTypes.actions.UPDATE_CLEANSE_TREATMENT]({ commit }, cleanse) {
    commit(dashTypes.mutations.SET_CLEANSE_TREATMENT, cleanse);
  },

  [dashTypes.actions.UPDATE_TREAT_FACE]({ commit }, treatFace) {
    commit(dashTypes.mutations.SET_TREAT_FACE, treatFace);
  },

  [dashTypes.actions.UPDATE_TREAT_EYES]({ commit }, treatEyes) {
    commit(dashTypes.mutations.SET_TREAT_EYES, treatEyes);
  },

  [dashTypes.actions.UPDATE_MOISTURIZE_TREATMENT]({ commit }, rebalanceAndMoisturize) {
    commit(dashTypes.mutations.SET_MOISTURIZE_TREATMENT, rebalanceAndMoisturize);
  },

  [dashTypes.actions.UPDATE_PROTECT_TREATMENT]({ commit }, protect) {
    commit(dashTypes.mutations.SET_PROTECT_TREATMENT, protect);
  },

  [dashTypes.actions.ADD_CREATE_TREATMENT_ERROR]({ commit }, createTreatmentError) {
    commit(dashTypes.mutations.ADD_CREATE_TREATMENT_ERROR, createTreatmentError);
  },

  [dashTypes.actions.RESET_CREATE_TREATMENT_ERRORS]({ commit }) {
    commit(dashTypes.mutations.RESET_CREATE_TREATMENT_ERRORS);
  },

  [dashTypes.actions.DELETE_CREATE_TREATMENT_ERROR]({ commit }, createTreatmentError) {
    commit(dashTypes.mutations.DELETE_CREATE_TREATMENT_ERROR, createTreatmentError);
  },

  [dashTypes.actions.VALIDATE_SUMMARY_TREATMENT]({ commit, getters: { treatFace, treatEyes } }) {
    const { compositionBottle1, compositionBottle2 } = treatFace;
    const { compositionBottle1: treatEyesBottle } = treatEyes;
    const faceSerums = [compositionBottle1, compositionBottle2];
    const hasFaceSerum = faceSerums.some(bottle => bottle !== null);

    commit(dashTypes.mutations.RESET_CREATE_TREATMENT_ERRORS);

    if (!hasFaceSerum && !treatEyesBottle) {
      return;
    }

    if (compositionBottle1 && isBottleEmpty(compositionBottle1)) {
      commit(
        dashTypes.mutations.ADD_CREATE_TREATMENT_ERROR,
        CREATE_TREATMENT_ERRORS.TREAT_FACE_FIRST_BOTTLE_SERUM_VALIDATION
      );
    }

    if (compositionBottle2 && isBottleEmpty(compositionBottle2)) {
      commit(
        dashTypes.mutations.ADD_CREATE_TREATMENT_ERROR,
        CREATE_TREATMENT_ERRORS.TREAT_FACE_SECOND_BOTTLE_SERUM_VALIDATION
      );
    }

    const isTwoSerumTreatment = faceSerums.every(bottle => bottle !== null);
    const hasTreatFaceOneBottleError =
      hasFaceSerum &&
      !isTwoSerumTreatment &&
      !isOneOfBottlesDuplicated(compositionBottle1, compositionBottle2);

    if (hasTreatFaceOneBottleError) {
      commit(
        dashTypes.mutations.ADD_CREATE_TREATMENT_ERROR,
        CREATE_TREATMENT_ERRORS.TREAT_FACE_ONE_BOTTLE_VALIDATION
      );
    }

    if (treatEyesBottle && isBottleEmpty(treatEyesBottle)) {
      commit(
        dashTypes.mutations.ADD_CREATE_TREATMENT_ERROR,
        CREATE_TREATMENT_ERRORS.TREAT_EYES_BOTTLE_SERUM_VALIDATION
      );
    }
  },

  async [dashTypes.actions.FINISH_CREATE_TREATMENT_PROCESS]({ getters, commit, dispatch }) {
    const { currentPatientId, getSummaryTreatmentId } = getters;

    await dispatch(dashTypes.actions.LOAD_PATIENT, currentPatientId);
    await dispatch(dashTypes.actions.LOAD_CURRENT_PATIENT_TREATMENTS);

    commit(dashTypes.mutations.SET_ACTIVE_TREATMENT_ID, getSummaryTreatmentId);

    await dispatch(dashTypes.actions.UPDATE_NOTIFICATIONS_COUNTER);
  },

  [dashTypes.actions.RESET_CREATE_TREATMENT_SELECTIONS]({ commit, dispatch }) {
    commit(dashTypes.mutations.SET_NOT_VALIDATED_DIAGNOSTIC_ID, null);

    dispatch(dashTypes.actions.RESET_SUMMARY_TREATMENT);
    dispatch(dashTypes.actions.RESET_CREATE_TREATMENT_TYPE);
    dispatch(dashTypes.actions.RESET_CREATE_TREATMENT_ERRORS);
    dispatch(dashTypes.actions.RESET_TREATMENT_RECOMMENDATION);
    dispatch(dashTypes.actions.RESET_RECOMMENDATION_DIAGNOSTIC);
  },

  async [dashTypes.actions.UPDATE_PATIENT_INFO]({ commit }, { patientId, patientData }) {
    const { status, data: responseData } = await api.updatePatientInfo(patientId, patientData);

    if (status >= 400) {
      return { errors: [responseData] };
    }

    if (!responseData.id || responseData.warning) {
      checkEmailErrors(commit, responseData);

      return;
    }

    commit(dashTypes.mutations.SET_CURRENT_PATIENT, responseData);

    return {};
  },

  async [dashTypes.actions.ADD_PATIENT_COMMENT]({ commit }, commentData) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: response } = await api.addPatientComment(commentData);

      if (response.id) {
        commit(dashTypes.mutations.ADD_PATIENT_COMMENT, response);

        return;
      }

      commit(dashTypes.mutations.ADD_ERROR, response.description);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  async [dashTypes.actions.EDIT_PATIENT_COMMENT]({ commit }, { id, text }) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: response } = await api.editPatientComment(id, {
        id,
        text
      });

      if (response.id) {
        commit(dashTypes.mutations.EDIT_PATIENT_COMMENT, response);

        return;
      }

      commit(dashTypes.mutations.ADD_ERROR, response.description);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  async [dashTypes.actions.DELETE_PATIENT_COMMENT]({ commit }, commentId) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      await api.deletePatientComment(commentId);

      commit(dashTypes.mutations.DELETE_PATIENT_COMMENT, commentId);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  [dashTypes.actions.SELECT_TREATMENT_DIRECTORY_RECOMMENDATIONS]({ commit, dispatch, getters }) {
    const {
      treatmentDirectoryRecommendations: recommendations,
      availableComplementaryCare
    } = getters;


    if (!recommendations || !recommendations.name) {
      throw new Error('No treatment directory recommendations in store');
    }

    const { treatmentComposition } = recommendations;
    commit(dashTypes.mutations.SET_TREATMENT_RECOMMENDATION, treatmentComposition);

    const PROTECT_PATH = `${CREATE_TREATMENT_SECTION_NAMES.PROTECT}.products`;
    const protectProducts = get(treatmentComposition, PROTECT_PATH, []);

    const nexUltraZ = protectProducts.find(({ name }) => name === PRODUCT_REFERENCES.SUNSCREEN_SPF);
    const isRecommended = get(nexUltraZ, 'isRecommended', false);

    const warningRecommendation = get(recommendations, 'treatmentWarning.recommendation');
    const isSPFWarningRecommendation = warningRecommendation === SPF_WARNING_RECOMMENDATION;

    const isAvailable = availableComplementaryCare.includes(PRODUCT_REFERENCES.SUNSCREEN_SPF);

    const canActivateNexUltraZ = isRecommended && isSPFWarningRecommendation && isAvailable;

    if (!canActivateNexUltraZ) {
      return;
    }

    dispatch(dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT, {
      section: CREATE_TREATMENT_SECTION_NAMES.PROTECT,
      product: { name: PRODUCT_REFERENCES.SUNSCREEN_SPF },
      isActive: true
    });
  },

  async [dashTypes.actions.LOAD_CUSTOM_CAMPAIGNS]({ commit }, userId) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: campaigns } = await api.getTemplateGroupsByTypes({
        userId,
        types: [TEMPLATE_GROUP_TYPES.CUSTOM_CAMPAIGN]
      });

      await commit(dashTypes.mutations.SET_MARKETING_CAMPAIGNS, campaigns);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  async [dashTypes.actions.CREATE_CUSTOM_CAMPAIGN](
    { commit, dispatch },
    { userId, customCampaign }
  ) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: response } = await api.createTemplateGroup({
        userId,
        templateGroupData: customCampaign
      });

      const { description } = response;

      if (description) {
        commit(dashTypes.mutations.ADD_ERROR, description);

        return;
      }

      dispatch(dashTypes.actions.LOAD_CUSTOM_CAMPAIGNS, userId);
      commit(dashTypes.mutations.SET_ACTIVE_MARKETING_CAMPAIGN, response);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  async [dashTypes.actions.LOAD_SKINXS_CAMPAIGNS]({ commit }, userId) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: campaigns } = await api.getTemplateGroupsByTypes({
        userId,
        types: [TEMPLATE_GROUP_TYPES.SKINXS_CAMPAIGN]
      });

      await commit(dashTypes.mutations.SET_MARKETING_CAMPAIGNS, campaigns);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  async [dashTypes.actions.UPDATE_MARKETING_CAMPAIGN](
    { commit, dispatch, getters },
    { userId, templateData }
  ) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      const { data: response } = await api.updateMarketingCampaignTemplate({
        userId,
        templateData
      });

      if (response.description) {
        commit(dashTypes.mutations.ADD_ERROR, response.description);

        return;
      }

      dispatch(dashTypes.actions.UPDATE_STORE_MESSAGE_TEMPLATE, response);

      if (!getters.activeMarketingCampaign.type === TEMPLATE_GROUP_TYPES.SKINXS_CAMPAIGN) {
        dispatch(dashTypes.actions.LOAD_CUSTOM_CAMPAIGNS, userId);
      }
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  async [dashTypes.actions.DELETE_MARKETING_CAMPAIGN](
    { commit, dispatch, getters },
    { userId, marketingCampaignId }
  ) {
    commit(dashTypes.mutations.CLEAR_ERRORS);

    try {
      await api.deleteMarketingCampaign({ userId, groupId: marketingCampaignId });

      const { marketingCampaigns } = getters;
      let activeCampaign = null;

      if (marketingCampaigns.length > 1) {
        const deletedCampaignIndex = findIndex(
          marketingCampaigns,
          ({ id }) => id === marketingCampaignId
        );

        const activeCampaignDirectionValue =
          marketingCampaigns.length - 1 === deletedCampaignIndex ? -1 : +1;

        const activeCampaignIndex = deletedCampaignIndex + activeCampaignDirectionValue;

        activeCampaign = marketingCampaigns[activeCampaignIndex] || null;
      }

      await dispatch(dashTypes.actions.LOAD_CUSTOM_CAMPAIGNS, userId);
      dispatch(dashTypes.actions.SET_ACTIVE_MARKETING_CAMPAIGN, activeCampaign);
    } catch (error) {
      commit(dashTypes.mutations.ADD_ERROR, error.message);
    }
  },

  async [dashTypes.actions.UPDATE_STORE_MESSAGE_TEMPLATE]({ commit }, template) {
    commit(dashTypes.mutations.SET_SAVED_MESSAGE_TEMPLATE, template);
    commit(dashTypes.mutations.SET_CURRENT_MESSAGE_TEMPLATE, template);
  },

  [dashTypes.actions.SET_ACTIVE_MARKETING_CAMPAIGN]({ commit }, campaign) {
    commit(dashTypes.mutations.SET_ACTIVE_MARKETING_CAMPAIGN, campaign);
  },

  [dashTypes.actions.SET_SAVED_MESSAGE_TEMPLATE]({ commit }, savedTemplate) {
    commit(dashTypes.mutations.SET_SAVED_MESSAGE_TEMPLATE, savedTemplate);
  },

  [dashTypes.actions.SET_CURRENT_MESSAGE_TEMPLATE]({ commit }, currentTemplate) {
    commit(dashTypes.mutations.SET_CURRENT_MESSAGE_TEMPLATE, currentTemplate);
  },

  [dashTypes.actions.SET_ACTIVE_MESSAGE_TEMPLATE_TAB]({ commit }, activeTab) {
    commit(dashTypes.mutations.SET_ACTIVE_MESSAGE_TEMPLATE_TAB, activeTab);
  },

  [dashTypes.actions.SET_UNIVERSKIN_H_SECTION_SELECTION]({ commit }, section) {
    commit(dashTypes.mutations.SET_UNIVERSKIN_H_SECTION_SELECTION, section);
  },

  async [dashTypes.actions.SET_MICRONEEDLING_ON]({ getters, dispatch }, serumOptions) {
    const { sectionName, usage, needleSize, bottleNumber } = serumOptions;

    await dispatch(dashTypes.actions.SET_MICRONEEDLING_OFF, { sectionName });

    const getPrice = getters[dashTypes.getters.PRODUCT_PRICE_BY_REFERENCE];
    const productReference = MICRONEEDLING_PRODUCT_SECTION_TO_PRODUCT_REFERENCE_MAP[sectionName];

    const price = getPrice(productReference);

    const microneedling = getMicroneedlingConfig({
      sectionName,
      usage,
      price,
      needleSize
    });

    const bottleKey = SERUM_BOTTLE_KEY[bottleNumber];
    const section = getters[dashTypes.getters.GET_SERUM_SUMMARY_BY_SECTION_NAME](sectionName);

    const serumSummary = {
      ...section,
      [bottleKey]: { ...section[bottleKey], microneedling }
    };

    dispatch(dashTypes.actions.SET_SERUM_SUMMARY_BY_SECTION_NAME, {
      sectionName,
      serumSummary
    });
  },

  async [dashTypes.actions.SET_MICRONEEDLING_OFF]({ dispatch, getters }, serumOptions) {
    const { sectionName } = serumOptions;

    const section = getters[dashTypes.getters.GET_SERUM_SUMMARY_BY_SECTION_NAME](sectionName);

    const serumSummary = { ...section };

    if (section.compositionBottle1) {
      serumSummary.compositionBottle1 = { ...section.compositionBottle1, microneedling: null };
    }

    if (section.compositionBottle2) {
      serumSummary.compositionBottle2 = { ...section.compositionBottle2, microneedling: null };
    }

    dispatch(dashTypes.actions.SET_SERUM_SUMMARY_BY_SECTION_NAME, {
      sectionName,
      serumSummary
    });
  },

  [dashTypes.actions.SET_SERUM_SUMMARY_BY_SECTION_NAME]({ commit }, { sectionName, serumSummary }) {
    commit(TREATMENT_SECTION_NAME_TO_MUTATION_TYPE_MAP[sectionName], serumSummary);
  },

  async [dashTypes.actions.UPDATE_MOISTURIZE_PRODUCTS]({ state, commit, dispatch }, productInfo) {
    const { product, isActive, isUpdate, modal } = productInfo;
    const { name, usage } = product;
    const actionParams = { ...productInfo, section: CREATE_TREATMENT_SECTION_NAMES.MOISTURIZE };

    if (RESTRICTED_MOISTURIZE_PRODUCTS.includes(name)) {
      const isActiveOrUpdated = isActive || isUpdate;

      if (isActiveOrUpdated) {
        const canSelect = await checkMoisturizeSelection(product, state.moisturizeUsages, modal);

        if (!canSelect) {
          return;
        }
      }

      dispatch(dashTypes.actions.REMOVE_FROM_MOISTURIZE_USAGES, { productName: name, usage });

      if (isActiveOrUpdated) {
        if (usage === SERUM_USAGE_KEYS.MORNING_AND_EVENING) {
          Object.values(state.moisturizeUsages).forEach(async productName => {
            if (!productName) {
              return;
            }

            await dispatch(dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT, {
              ...actionParams,
              product: { name: productName },
              isActive: productName === name
            });
          });

          commit(dashTypes.mutations.RESET_MOISTURIZE_USAGES);
          commit(dashTypes.mutations.SET_MOISTURIZE_USAGES, { name, usage });
        } else {
          const selectedUsage = handleMorningOrEveningUsageSelection(
            product,
            state.moisturizeUsages
          );

          if (selectedUsage.productName) {
            await dispatch(dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT, {
              ...actionParams,
              product: { name: selectedUsage.productName },
              isActive: false
            });

            commit(dashTypes.mutations.SET_MOISTURIZE_USAGES, {
              name: null,
              usage: selectedUsage.key
            });
          }

          commit(dashTypes.mutations.SET_MOISTURIZE_USAGES, { name, usage });
        }
      }
    }

    dispatch(dashTypes.actions.UPDATE_COMPLEMENTARY_CARE_PRODUCT, { ...actionParams });

    if (!isUpdate) {
      await dispatch(dashTypes.actions.TOGGLE_COMPLEMENTARY_CARE_PRODUCT, {
        ...actionParams
      });
    }
  },

  [dashTypes.actions.REMOVE_FROM_MOISTURIZE_USAGES]({ commit, state }, { productName }) {
    const { moisturizeUsages } = state;

    const updatedUsages = removeFromMoisturizeUsages(productName, moisturizeUsages);

    commit(dashTypes.mutations.UPDATE_MOISTURIZE_USAGES, updatedUsages);
  }
};
