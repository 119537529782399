export const PHOTOTYPE = [
  'phototype1',
  'phototype2',
  'phototype3',
  'phototype4',
  'phototype5',
  'phototype6'
];


export const SKIN_THICKNESS = ['thin', 'thick'];

export const NOT_APPLICABLE_KEY = 'notApplicable';

export const YES = ['yes'];
export const NO = ['no'];
export const NOT_APPLICABLE = [''];

export const YES_NO = [...YES, ...NO];
export const YES_NO_WITH_NOT_APPLICABLE = [...YES_NO, ...NOT_APPLICABLE];
export const NO_WITH_NOT_APPLICABLE = [...NO, ...NOT_APPLICABLE];

export const MILD = ['mild'];
export const MODERATE = ['moderate'];
export const SEVERE = ['severe'];

export const NO_MODERATE_SEVERE = [...NO, ...MODERATE, ...SEVERE];
export const NO_MILD_MODERATE_SEVERE = [...NO, ...MILD, ...MODERATE, ...SEVERE];

export const MENOPAUSAL_VALUES = ['perimenopausal', 'menopausal', 'postmenopausal'];
export const MENOPAUSAL_STATE = [...MENOPAUSAL_VALUES, ...NO, ...NOT_APPLICABLE];

export const FOOD_ALLERGY = [
  'fruit',
  'rice',
  'garlic',
  'oats',
  'meat',
  'milk',
  'peanuts',
  'fish_or_shellfish',
  'soy',
  'tree_nuts',
  'wheat',
  'hot_peppers',
  'gluten',
  'eggs',
  'tartrazine',
  'sulfites'
];

export const MEDICAL_ALLERGY = [
  'balsam_of_peru',
  'tetracycline',
  'dilantin',
  'tegretol',
  'penicillin',
  'cephalosporins',
  'sulfonamides',
  'asprin',
  'local_anaesthetics'
];

export const ENVIRONMENTAL_ALLERGY = [
  'pollen',
  'cats_or_dogs',
  'sun',
  'insect_stings',
  'mold',
  'dust_mites'
];

export const SUBSTANCE_ALLERGY = [
  'perfume',
  'cosmetics',
  'nickel',
  'gold',
  'chromium',
  'cobalt',
  'formaldehyde',
  'fungicide',
  'latex',
  'dimethylaminopropylamine',
  'paraphenylenediamine'
];

export const ALLERGIES = [
  ...FOOD_ALLERGY,
  ...MEDICAL_ALLERGY,
  ...ENVIRONMENTAL_ALLERGY,
  ...SUBSTANCE_ALLERGY
];

export const GROUPED_ALLERGIES = [
  {
    allergyType: 'food',
    allergies: FOOD_ALLERGY
  },
  {
    allergyType: 'medications',
    allergies: MEDICAL_ALLERGY
  },
  {
    allergyType: 'environmentalFactors',
    allergies: ENVIRONMENTAL_ALLERGY
  },
  {
    allergyType: 'substances',
    allergies: SUBSTANCE_ALLERGY
  }
];
